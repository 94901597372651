import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { SignIn, useAuth } from '@clerk/nextjs'
import { Box, Flex } from '@nexpy/design-system'
import { useClientSide } from 'hooks/useClientSide'
import { AuthRoutes } from 'routes/auth'

import FadeIn from 'components/generics/FadeIn'
import Title from 'components/generics/Title'

import styles from './Login.module.scss'

const Login = () => {
  const [canShow, setCanShow] = useState<boolean>(false)

  const router = useRouter()

  const { isLoaded, isSignedIn } = useAuth()

  useEffect(() => {
    router.prefetch(AuthRoutes.REGISTER)
  }, [router])

  const isClientSide = useClientSide()

  useEffect(() => {
    setCanShow(isClientSide && isLoaded && !isSignedIn)
  }, [isClientSide, isLoaded, isSignedIn])

  return (
    <FadeIn>
      <Flex
        variant='centerScreen'
        flexDirection='column'
        opacity={canShow ? '1' : '0'}
        transition='opacity 0.2s ease 0.1s'
      >
        <Title text='Login - Nexpy' />

        <Box>
          <Flex justifyContent='center'>
            <SignIn
              appearance={{
                elements: { card: styles.card },
              }}
            />
          </Flex>
        </Box>
      </Flex>
    </FadeIn>
  )
}

export default Login

import Head from 'next/head'
import { memo, useMemo } from 'react'

import { OneSignalContext } from 'contexts/OneSignalContext'

import { META_TAGS_IDENTIFIER_KEYS } from 'constants/meta'

type TitleProps = {
  text: string
}

const Title = ({ text }: TitleProps) => {
  const value = useMemo(() => text, [text])

  const hasSomeNotificationUnviewed = OneSignalContext.useSelector(
    state => state.hasSomeNotificationUnviewed
  )

  if (hasSomeNotificationUnviewed) {
    return null
  }

  return (
    <Head>
      <title key={META_TAGS_IDENTIFIER_KEYS.TITLE}>{value}</title>
    </Head>
  )
}

export default memo(Title)
